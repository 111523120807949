export default function handleFrontPageCacheMiss() {
  document.addEventListener('DOMContentLoaded', () => {
    const frontPageMobile = document.querySelector('.front-page-boko');
    const frontPageDesktop = document.querySelector('.front-page-baka');
    const reportCacheMissEndpoint = '/wp-json/nrp-front-page/v1/report-cache-miss';

    let body;

    if (window.innerWidth < 768 && frontPageDesktop) {
      body = {
        device: 'mobile'
      };
    } else if (window.innerWidth >= 768 && frontPageMobile) {
      body = {
        device: 'desktop'
      };
    }

    if (body) {
      fetch(reportCacheMissEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...body,
          url: window.location.href,
          user_agent: window.navigator.userAgent,
          referrer: document.referrer
        })
      });
    }
  });
}
