import '../scss/styles.scss';
import { countdownService, imgLazyloadService } from '../services';
import { scrollToTop } from '../utils';
import handleFrontPageCacheMiss from './handleFrontPageCacheMiss';
import NrpFrontPageData from '../../types/NrpFrontPageData';

declare const nrpFrontPageData: NrpFrontPageData;

(($, { articleData }) => {
  $(() => {
    scrollToTop();
    imgLazyloadService();

    if (articleData) {
      // check out for param in url frontPageLive
      const frontPageLive = window.location.search.includes('frontPageLive');
      if (frontPageLive) {
        return;
      }

      countdownService.initCountdowns(articleData);
    }
  });
  handleFrontPageCacheMiss();
})(jQuery, nrpFrontPageData);
